import { useState,useEffect } from "react";
// import ImageBlock from "./cimageblock";
import Generate from "./generate";
import styled from "styled-components";
import GetImageHolder from './pimageholder'
import { useNavigate } from 'react-router-dom';

export default function Create({user}) {
    const [disableGenerate, setDisableGenerate] = useState(false);
    const [selectedImgId,setSelectedImgId] = useState(null);
    const [urls, setUrls] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      if (user === undefined) {
        navigate(`/signin`);
      }
    }, []);

    return (
      <Main>
        <ImageInputs>
          <Generate
            urls={urls}
            setUrls={setUrls}
            user={user}
            disableGenerate={disableGenerate}
            setDisableGenerate={setDisableGenerate}
            selectedImgId={selectedImgId}
            setSelectedImgId={setSelectedImgId}
          />
        </ImageInputs>
        <GeneratedImages>
            <GetImageHolder user={user}
                            urls={urls}
                            setUrls={setUrls}
                            disableGenerate={disableGenerate}
                            selectedImgId={selectedImgId}
                            setSelectedImgId={setSelectedImgId}

            />
        </GeneratedImages>
      </Main>
    );
  }





const GeneratedImages = styled.div`
  /* border: 2px solid green; */
  
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  flex: 1 1 400px;
  align-items: center;
  justify-content: strech;
  /* padding: 100px; */
  @media (max-width: 600px) {
    padding: 2px;
  }
`;



const Main = styled.div`
  display: flex;
  height: 100vh;
  overflow: auto;
  min-width: 400px;
  
  @media (max-width: 600px) {
    flex-direction: column;
    background: #100f0f;
  }
`;

const ImageInputs = styled.div`
  border-right: 0.1px solid rgba(255, 255, 255, 0.5);
  /* border:2px solid green; */
  display: flex;
  flex-direction: column;
  flex: 1 1 400px;
  min-width: 400px;
  max-width: 400px;
  position: sticky;
  top: 0;
  align-self: flex-start;
  overflow: auto;

  height: 100%;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 90%;
    /* overflow: auto; */
    border-right: none;
    position: relative;
    /* height: auto; */
  }
`;
