import { useState, useEffect } from "react";
import { backUrl } from "../config";

import styled from "styled-components";
import ImageBlock from "../components/imageblock";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ListImages({ user, myImages }) {
  const [urls, setUrls] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  async function getImages() {
    const apiEndPoint = myImages ? "getmyimgs" : "getimgs";
    await axios
      .post(backUrl + apiEndPoint, {
        jwt: user.signInUserSession.accessToken.jwtToken,
        identifier: page,
      })
      .then((resp) => {
        const resp_urls = resp["data"]["imgmeta"];
        if (resp_urls.length === 0) {
          setHasMore(false);
        } else {
          setUrls([...urls, ...resp["data"]["imgmeta"]]);
          setPage(page + 1);
        }
      })
      .catch((err) => {
        console.log(err.message + " Issue getting images");
      });
  }

  async function listImages() {
    await axios
      .get(backUrl + `/?page=${page}`)
      .then((resp) => {
        const resp_urls = resp["data"]["imgmeta"];
        if (resp_urls.length === 0) {
          setHasMore(false);
        } else {
          setUrls([...urls, ...resp["data"]["imgmeta"]]);
          setPage(page + 1);
        }
      })
      .catch((err) => {
        console.log(err.message + " Issue getting images");
      });
  }

  function getImageSelector() {
    if (user !== null && user !== undefined) {
      return getImages();
    }
    return listImages();
  }

  useEffect(() => {
    if (user !== null) {
      getImages();
    }
  }, [user]);

  useEffect(() => {
    if (user === undefined || user === null) {
      listImages();
    }
  }, []);

  return (
    <Wrapper
      dataLength={urls.length}
      next={() => getImageSelector()}
      height={1000}
      hasMore={hasMore}
    >
      {urls &&
        urls.map((o, i) => (
          <ImageBlock
            imgmeta={o}
            key={o["img_id"]}
            user={user}
            urls={urls}
            setUrls={setUrls}
            myImages={myImages}
            urlsIndex={i}
          />
        ))}
    </Wrapper>
  );
}

const Wrapper1 = styled.div`
  color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background: #100f0f;
  padding: 1rem;

  @media (max-width: 400px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

const Wrapper = styled(InfiniteScroll)`
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  overflow: auto;
  flex: 5 1 600px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background: #100f0f;
`;
