// import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";

import SignIn from "./components/signin";
import GlobalNav from "./components/nav";
import styled from "styled-components";
import Create from "./components/create";
import Terms from "./components/terms";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ListImages from "./components/listImages";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
import { backUrl } from "./config";
import { Toaster } from 'react-hot-toast';
// const updatedAwsConfig = {
//   ...awsconfig,
//   oauth: {
//     ...awsconfig.oauth,
//     redirectSignIn: "http://localhost:3000/",
//     redirectSignOut: "http://localhost:3000/",
//   },
// };

// Amplify.configure(updatedAwsConfig);
Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState(null);
  async function updateUserInfo(){
    if (user !==null){
      const attributes = user['attributes'] 
      await axios
            .post(backUrl + "user", {
                      jwt:user.signInUserSession.accessToken.jwtToken,
                      user_id:user['username'],
                      name:attributes['name'],
                      email:attributes['email'],
                      given_name:attributes['given_name'],
                      picture:attributes['picture']
            })
            .catch((err) => {
              console.log(err.message + " Please try after some time.");
            });  
    }
    
  }
  
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
      }
    });

    getUser().then((userData) => {setUser(userData);
    // updateUserInfo()
  });
  }, []);

  useEffect(() => {
    updateUserInfo()
  },[user]);

  async function getUser() {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      
      return userData;
    } catch {
      return console.log("Not signed in");
    }
  }

  function Gallery({myImages}) {
    return (
      <ListImages user={user} myImages={myImages}></ListImages>
    );
  }

  function PrivateGallery({myImages}) {
    return (
      <ListImages user={user} myImages={myImages}></ListImages>
    );
  }

  return (
    <BrowserRouter>
      <div className="Hero">
        <Wrapper>
          <GlobalNav user={user} />
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/create" element={<Create user={user} />} />
            <Route path="/termsandservices" element={<Terms />} />
            <Route path="/mygallery" element={<PrivateGallery myImages={true}/>} />
            <Route path="/" element={<Gallery myImages={false}/>} />
          </Routes>
        </Wrapper>
        <Toaster/>
      </div>
    </BrowserRouter>
  );
}

export default App;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #100f0f;

  @media (max-width: 300px) {
    flex-direction: row;
    width: 100%;
    height: 100vh;
  }
`;







