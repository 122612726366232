/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:2868d87e-8d23-4e0c-929c-d5fd6e52e859",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_3SOYfRX9m",
    "aws_user_pools_web_client_id": "7tl2nd6eb8cb8vb73bdenv9gt3",
    "oauth": {
        "domain": "art2artmainf2d527a3-f2d527a3-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://art2art.ai/",
        "redirectSignOut": "https://art2art.ai/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "GIVEN_NAME",
        "NAME",
        "PICTURE"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
