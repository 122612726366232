import React from 'react'
import styled from "styled-components";
import { useState,useEffect } from 'react';
import ReactMarkdown from 'react-markdown'




export default function Terms(){
    const file_name = 'terms.md';
    const [post, setPost] = useState('');


    useEffect(() => {
        import(`../docs/${file_name}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPost(res))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    });
    
    
    return(
        <Wrapper>
            <ReactMarkdown children={post}/>
        </Wrapper>
    );
    // , remarkRehype,remarkToc
}

const Wrapper = styled.div`
    background: black;
    padding-left: 7rem;
    padding-top: 2rem;
    padding-right: 7rem;
    
    height: auto;
    color:gray;
    font-size: medium;
    overflow-y: auto;
    
    h1,h2,h3{
        /* opacity: 0.7; */
        
    }
    p,li{
        /* opacity: 0.8; */
        font-size: small;
        opacity: 0.9;
    }
    /* opacity: 0.8; */
    
`
    
    

