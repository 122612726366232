import styled from "styled-components";
import GoogleButton from "react-google-button";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { ReactComponent as A2ALogo } from "../imgs/Abrown2.svg";

export default function SignIn() {
  return (
    <Wrapper>

        <Logo>
          <div>
            <A2ALogo height={100} width={100} />
          </div>
          {/* <A2ALogo height={100} width={100} /> */}
          <h3>Art2Art</h3>
        </Logo>
      
      <p>Log in to create AI powered images using Stable Diffusion</p>
      <GoogleButton
        type="dark"
        onClick={() =>
          Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
          })
        }
      />
    </Wrapper>
  );
}
const Logo = styled.div`
  display: flex;
  h3{
    /* border: 2px solid red; */
    font-size: 800;
    align-self: center;
    color: #dea028;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top:10rem;
  height: 100%;
  width: 99%;
  color: white;
  /* justify-content: center; */
  align-items: center;
  h1 {
    margin: 0;
    margin-top: 10rem;
    margin-bottom: 2rem;
    display: flex;
  }
  p {
    margin: 0;
    margin-bottom: 3rem;
  }
`;
const TextWrapper = styled.span`
  color: #dea028;
  //align-self: center;
`;
