import axios from "axios";
import { backUrl } from "../config";
import { X, Lock, Unlock} from "lucide-react";
import { Dna } from "react-loader-spinner";
import styled from "styled-components";
import toast from 'react-hot-toast';

const notify_public_status = (msgid) => {
  const msg_status = {1:"Wow 😍 Image is Public now",
                      0:"Image is private now 🤗",
                      2:"Deleted successfully"
                      }
  toast(msg_status[msgid], {
    style : {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });
 
}

function GetSpinner() {
    return (
      <Spinner>
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      </Spinner>
    );
  }

function ImageBlock({ imgmeta,
urls, 
user, 
setUrls, 
setSelectedImgId
}) {
    const onLock = async (e) => {
      e.preventDefault();
      const resp = await axios.post(backUrl + "lockimg", {
          jwt: user.signInUserSession.accessToken.jwtToken,
          identifier: imgmeta["img_id"],
      });
      if (resp["status"] === 200) {
        const new_urls = urls.map((el) => {
          if(imgmeta["img_id"] !== el["img_id"]){
            return el
          }
          else{
            el['public'] = resp['data']['public']
            return el
          }
        })
        setUrls(new_urls)}        
        notify_public_status(resp['data']['public'])
      };
    
    const onDelete = async (e) => {
    e.preventDefault();
    
    const resp = await axios.post(backUrl + "delimg", {
        jwt: user.signInUserSession.accessToken.jwtToken,
        identifier: imgmeta["img_id"],
    });
    
    if (resp["status"] === 200) {
        urls && setUrls(urls.filter((el) => el["img_id"] !== imgmeta["img_id"]));
        setSelectedImgId(null);
        notify_public_status(2)
    }
    
    
    };

    const onImageClick = (e) =>{
        e.preventDefault();
        setSelectedImgId(imgmeta['img_id'])
        
    }

    return (
    <ImageWrapper height={imgmeta['height']} width={imgmeta['width']}>
        <CtrlBar>
          <Button type="submit" onClick={onLock}>
              {Boolean(imgmeta['public']) && <Unlock size={15}/>}
              {!Boolean(imgmeta['public']) && <Lock size={15}/>}
          </Button>
          <Button type="submit" onClick={onDelete}>
              <X size={15}/>    
          </Button>
        </CtrlBar>
        <Image onClick={onImageClick}>  
        <img src={imgmeta["url"]} alt="" />
        </Image>
    </ImageWrapper>
    );
}

export default function GetImageHolder({
    user,
    urls,
    setUrls,
    disableGenerate,
    selectedImgId,
    setSelectedImgId}) {
    // if (disableGenerate) {
    //     return <GetSpinner />;
    //   }
    function getUrl(){
        let index = 0;
        if (selectedImgId!==null){
            // const selectedImg = urls.filter((el) => el.img_id == selectedImgId)[0]
            // return selectedImg['url']
            index = urls.findIndex(el => el['img_id']===selectedImgId )
            if (index === -1){
                index = 0;
            }
            
        }
        
        return index;

        
    }
    const index = getUrl()
    
 
  return (
   
    <Wrapper>
        <MainImage>
            {disableGenerate && (<GetSpinner/>)}
            {!disableGenerate && (urls.length!==0) && <img src={urls[index]['url']} alt="" />}
        </MainImage>
        
        <ImageHolder>
                {urls && (
                    urls.map((o,i) => (
                    
                    <ImageBlock
                        imgmeta={o}
                        key={o['img_id']}
                        user={user}
                        urls={urls}
                        setUrls={setUrls}
                        setSelectedImgId={setSelectedImgId}
                        />
            ))
            
        )}

        </ImageHolder>
      </Wrapper>
    //   <ImageHolder urls={urls} user={user} setUrls={setUrls} />
  );
}

const Button = styled.button` 
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background: #313134;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  &:hover {
    outline: 0.2px solid white;
  }
  &:active {
    background: #e5e5e5;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
  }
`;


const CtrlBar = styled.div`
  display: flex;
  position: absolute;
  justify-content: end;
  padding-top: 5px;
  padding-right: 0.5rem;
  gap: 0.2rem;
  color: white;
  z-index: 1;
  top: 0px;
  right: 0px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  width:100%;
`;


const Wrapper = styled.div`
    margin: 0;
    padding: 0;
    /* border: 2px solid red; */
    overflow-y: scroll;
    width: 100%;
    height:100%;
`

const MainImage = styled.div`
    display: flex;
    width: 100%;
    height: 70%;
    justify-content: center;
    /* border: 2px solid grey; */
    padding-top: 2rem;
    padding-bottom: 2rem;
`
const ImageHolder = styled.div`
display: flex;
flex-wrap: wrap;
/* flex: 1 1 256px; */
overflow-y: scroll;
`

const ImageWrapper = styled.div`
    display: flex;
    flex: 0 0 256px;
    /* width: ${(props) => (props.width)};
    height: ${(props) => (props.height)}; */
    
    position : relative;
    &:not(:hover) ${CtrlBar} {
      display: none;
    }
    

    
    
`

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100;
`;

const Image = styled.div`
  position: relative;
  padding: 3px;
  @media (max-width: 400px) {
    width: 100%;
    height: auto;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
  }
`;