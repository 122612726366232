import styled from "styled-components";
import {
  X,
  Heart,
  Copy,
  Lock,
  Unlock,
  ArrowLeft,
  ArrowRight,
} from "lucide-react";
import { Alert } from "@reach/alert";
import { useState } from "react";
import { backUrl } from "../config";
import axios from "axios";
import { Dialog } from "@reach/dialog"; //, DialogOverlay, DialogContent
import "@reach/dialog/styles.css";
import toast from "react-hot-toast";

const notify_public_status = (msgid) => {
  const msg_status = {
    1: "Wow 😍 Image is Public now",
    0: "Image is private now 🤗",
    2: "Deleted successfully",
  };
  toast(msg_status[msgid], {
    style: {
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    },
  });
};

function Hp({ heading, text }) {
  return (
    <Label>
      {heading}
      <p>{text}</p>
    </Label>
  );
}

function Example({ showDialog, open, close, imgmeta, urls, urlsIndex }) {
  const [urlIndex, setUrlIndex] = useState(urlsIndex);

  const hwtext =
    "Height - " + imgmeta["height"] + ", Width - " + imgmeta["width"];
  return (
    <HighLighter isOpen={showDialog} onDismiss={close}>
      <ArrowButton
        type="submit"
        onClick={() => {
          setUrlIndex(urlIndex !== 0 ? urlIndex - 1 : 0);
        }}
      >
        <ArrowLeft size={20} />
      </ArrowButton>
      {/* isOpen={showDialog} onDismiss={close} */}
      <ImgBox>
        <img
          src={urls[urlIndex]["url"]}
          loading="lazy"
          alt=""
          referrerPolicy="no-referrer"
        />
      </ImgBox>
      <ImgData>
        <Hp heading={"Prompt:"} text={urls[urlIndex]["prompt"]} />
        <Hp
          heading={"Guidance Scale:"}
          text={urls[urlIndex]["guidance_scale"]}
        />
        <Hp heading={"Dimensions:"} text={hwtext} />
        <Hp heading={"Seed:"} text={urls[urlIndex]["seed"]} />
        <Hp heading={"Model:"} text={"Stability Diffusion"} />
      </ImgData>
      <ArrowButton
        type="submit"
        onClick={() => {
          setUrlIndex(
            urlIndex !== urls.length - 1 ? urlIndex + 1 : urls.length - 1
          );
        }}
      >
        <ArrowRight size={20} />
      </ArrowButton>
    </HighLighter>
  );
}

export default function ImageBlock({
  imgmeta,
  user,
  urls,
  setUrls,
  myImages,
  urlsIndex,
}) {
  const [liked, setLiked] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  const onLock = async (e) => {
    e.preventDefault();
    const resp = await axios.post(backUrl + "lockimg", {
      jwt: user.signInUserSession.accessToken.jwtToken,
      identifier: imgmeta["img_id"],
    });
    if (resp["status"] === 200) {
      const new_urls = urls.map((el) => {
        if (imgmeta["img_id"] !== el["img_id"]) {
          return el;
        } else {
          el["public"] = resp["data"]["public"];
          return el;
        }
      });
      setUrls(new_urls);
    }
    notify_public_status(resp["data"]["public"]);
  };

  const onLike = async (e) => {
    e.preventDefault();
    const resp = await axios.post(backUrl + "likeimg", {
      jwt: user.signInUserSession.accessToken.jwtToken,
      identifier: imgmeta["img_id"],
    });
    if (resp["status"] === 200) {
      const new_urls = urls.map((el) => {
        if (imgmeta["img_id"] !== el["img_id"]) {
          return el;
        } else {
          el["has_liked"] = resp["data"]["has_liked"];
          el["likes"] = resp["data"]["likes"];
          return el;
        }
      });
      // setLiked(Boolean(resp["data"]["liked"]));
      setUrls(new_urls);
    }
  };

  const onDelete = async (e) => {
    e.preventDefault();
    const resp = await axios.post(backUrl + "delimg", {
      jwt: user.signInUserSession.accessToken.jwtToken,
      identifier: imgmeta["img_id"],
    });
    if (resp["status"]) {
      urls && setUrls(urls.filter((el) => el["img_id"] !== imgmeta["img_id"]));
      notify_public_status(2);
    }
  };

  const handleCopy = async (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(imgmeta["prompt"]);
    setCopied(true);
  };

  return (
    <Box>
      <Example
        showDialog={showDialog}
        open={open}
        close={close}
        imgmeta={imgmeta}
        urls={urls}
        urlsIndex={urlsIndex}
      />
      <CtrlBar>
        <MyAlert copied={copied}>Copied</MyAlert>

        <HeartButton type="submit" onClick={onLike}>
          <p>{imgmeta["likes"]}</p>
          <RedHeart liked={imgmeta["has_liked"]} size={18} />
        </HeartButton>
        {myImages && (
          <Button type="submit" onClick={onLock}>
            {Boolean(imgmeta["public"]) && <Unlock size={18} />}
            {!Boolean(imgmeta["public"]) && <Lock size={18} />}
          </Button>
        )}
        <Button>
          <Copy
            onClick={handleCopy}
            onMouseOut={() => {
              setCopied(false);
            }}
          />
        </Button>
        {myImages && (
          <Button type="submit" onClick={onDelete}>
            <X />
          </Button>
        )}
      </CtrlBar>
      <Image onClick={open}>
        <img src={imgmeta["url"]} alt="" />
        <ImgFooter>
          {/* <p>{imgmeta["prompt"].substr(0, 200)} ...</p> */}
          <UserPic>
            <img
              src={imgmeta["picture"]}
              alt=""
              referrerPolicy="no-referrer"
              loading="lazy"
            />
          </UserPic>
          <p>{imgmeta["name"]}</p>
        </ImgFooter>
      </Image>
    </Box>
  );
}

const HighLighter = styled(Dialog)`
  display: flex;
  align-items: center;
  width: 80%;
  height: 80%;
  background: #100f0f;
  border-radius: 10px;
`;

const UserPic = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 25px;
  height: 25px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 60%;
    object-fit: contain;
  }
`;

const ImgBox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 60%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const ImgData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  color: white;
  padding: 2rem;
`;

const ImgMeta = styled.div`
  display: flex;
  position: absolute;
  padding-top: 13px;
  padding-left: 10px;
  gap: 2px;
  color: white;
  z-index: 1;
  top: 0px;
  left: 0px;
  height: 30px;
  p {
    margin: 0;
    padding: 0;
    opacity: 0.8;
    font-size: small;
  }
`;

const CtrlBar = styled.div`
  display: flex;
  position: absolute;
  justify-content: end;
  padding-top: 5px;
  padding-right: 0.5rem;
  gap: 0.2rem;
  color: white;
  z-index: 1;
  top: 0px;
  right: 0px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
`;
const ImgFooter = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow-wrap: hidden;
  justify-content: flex-start;
  padding: 3px;
  color: white;
  bottom: 0px;
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  p {
    display: block;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    height: 100%;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
    /* border: 2px solid white; */
  }
`;
const Box = styled.div`
  position: relative;
  flex: 1 1 256px;
  max-height: 512px;
  max-width: 512px;
  padding: 0.1rem;
  @media (max-width: 400px) {
    width: 100%;
    height: auto;
  }
  &:not(:hover) ${CtrlBar} {
    display: none;
  }
`;
const Label = styled.label`
  margin-top: 5px;
  padding-top: 5px;
  font-weight: 700;
  opacity: 0.8;
  p {
    font-weight: 400;
    font-size: 0.9rem;
    margin: 0;
    margin-top: 0.2rem;

    opacity: 0.6;
  }
`;
const Image = styled.div`
  position: relative;
  flex: 1 1 256px;
  padding: 3px;
  max-height: 512px;
  max-width: 512px;
  @media (max-width: 400px) {
    width: 100%;
    height: auto;
  }
  img {
    display: block;
    width: 100%;
    max-width: 512px;
    max-height: 512px;
    height: 100%;
    margin: auto;
  }

  &:not(:hover) ${ImgFooter} {
    display: none;
  }
  &:not(:hover) ${ImgMeta} {
    display: none;
  }
  &:hover {
    img {
      /* filter: brightness(50%); */
    }
  }
`;

const Button = styled.button`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  width: 30px;
  background: #313134;

  border: none;
  border-radius: 8px;

  color: #ffffff;
  &:hover {
    outline: 0.2px solid white;
  }
  &:active {
    background: #e5e5e5;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
  }
`;

const ArrowButton = styled.button`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  height: 35px;
  width: 35px;
  /* background: none; */
  background: #313134;
  border: none;
  border-radius: 50%;
  color: white;
  /* color: #ffffff; */
  p {
    margin: 0;
    padding: 0;
    padding-right: 0.2rem;
  }
  /* &:hover {
    outline: 0.2px solid white;
  } */
  &:active {
    background: #222224;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
  }
`;
const HeartButton = styled.button`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
  height: 30px;
  width: 38px;
  background: none;
  /* background: #313134; */

  border: none;
  border-radius: 8px;

  color: #ffffff;
  p {
    margin: 0;
    padding: 0;
    padding-right: 0.2rem;
  }
  /* &:hover {
    outline: 0.2px solid white;
  } */
  &:active {
    background: #e5e5e5;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
  }
`;

const RedHeart = styled(Heart)`
  ${(props) => !props.liked} {
    color: red;
    fill: red;
  }
`;

const MyAlert = styled(Alert)`
  display: ${(props) => (props.copied ? "block" : "none")};
`;

// const LikesCount = styled.p`
//   /* border: 2px solid red; */
//   margin: 0;
//   padding: 0;
//   margin: 2px;
// `;
