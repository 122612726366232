import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { ChevronsDown, ChevronsUp } from "lucide-react";

import { Slider } from "@reach/slider";
import "@reach/slider/styles.css";
import { backUrl } from "../config";

function GetSlider({
  lbl_txt,
  lbl_desc,
  min,
  max,
  step,
  sdProps,
  setSdProps,
  prop_name
}) {
  // console.log(sdProps[prop_name])
  return (
    <SliderComponent>
      <SliderMeta>
        {lbl_txt}
        <p>{sdProps[prop_name]}</p>
      </SliderMeta>
      <SliderDesc>
        <p>{lbl_desc}</p>
      </SliderDesc>
      <MySlider
        min={min}
        max={max}
        step={step}
        // defaultValue={sdProps[prop_name]}
        value={sdProps[prop_name]}
        onChange={(e) => {
          let temp = {...sdProps}
          temp[prop_name] = e
          setSdProps(temp)
        }}
      />
    </SliderComponent>
  );
}

export default function Generate({
  urls,
  setUrls,
  user,
  disableGenerate,
  setDisableGenerate,
  selectedImgId,
  setSelectedImgId,
}) {
  const [sdProps,setSdProps] = useState({'prompt_text':'',
                                        'cfg':9.0,
                                        'height':768,
                                        'width':768,
                                        'step':25,
                                        'seedValue':null,
                                        'numImages':1,
                                        'n_prompt':''
                                        })
  const [advOptions, setAdvOptions] = useState(false);

  useEffect(() => {
    const img_props = urls.filter((o) => (
      o['img_id']===selectedImgId
    ))[0]
    if (selectedImgId!==null){
      const temp = {'prompt_text':img_props['prompt'],
                    'cfg':img_props['guidance_scale'],
                    'height':img_props['height'],
                    'width':img_props['width'],
                    'step':img_props['steps'],
                    'seedValue':img_props['seed'],
                    'numImages':1,
                    'n_prompt':img_props['n_prompt']

                    }
      setSdProps({...temp})
    }
  },[selectedImgId]);

  const onGenerate = async (e) => {
    setDisableGenerate(true);
    setSelectedImgId(null);
    e.preventDefault();

    let jwt = "";
    if (user !== null) {
      jwt = user.signInUserSession.accessToken.jwtToken;
    }
    const getImage = await axios
      .post(backUrl + "genimage", {
        prompt: sdProps['prompt_text'],
        guidance_scale: sdProps['cfg'],
        seed: parseInt(sdProps['seedValue']),
        height: sdProps['height'],
        width: sdProps['width'],
        step: sdProps['step'],
        num_images: sdProps['numImages'],
        jwt: jwt,
        n_prompt:sdProps['n_prompt']
      })
      .catch((err) => {
        setDisableGenerate(false);
        console.log(err.message + " Please try after some time.");
      });
    if (getImage) {
      setUrls([...getImage["data"],...urls]);
    }
    setDisableGenerate(false);
  };

  const handleAdvOptions = async (e) => {
    e.preventDefault();
    setAdvOptions(!advOptions);
  };

  function GetAdvButton() {
    if (advOptions) {
      return <ChevronsUp />;
    }
    return <ChevronsDown />;
  }

  function GenImageButton({ processing }) {
    return (
      <Button
        type="submit"
        disabled={processing | disableGenerate | (user == null)}
        onClick={onGenerate}
      >
        <span>{disableGenerate ? "Please Wait .." : "Generate"}</span>
      </Button>
    );
  }

  return (
    <Wrapper>
      <Label>
        Prompt
        <p>A sentence describing what you would like to draw</p>
      </Label>

      <PromptInput
        type="text"
        name="name"
        value={sdProps['prompt_text']}
        onChange={(e) => {
          let temp = {...sdProps}
          temp['prompt_text'] = e.target.value
          setSdProps(temp)

        }}
      />
      <Label>
        Negative Prompt
        <p>What you dont want the image to contain</p>
      </Label>

      <Input
        type="text"
        name="name"
        value={sdProps['n_prompt']}
        onChange={(e) => {
          let temp = {...sdProps}
          temp['n_prompt'] = e.target.value
          setSdProps(temp)

        }}
      />
      <GenImageButton
        processing={sdProps['prompt_text'].length === 0 ? true : false}
      ></GenImageButton>
      <Warn signedin={user == null}>SignIn to generate images</Warn>
      <AdvanceOptions onClick={handleAdvOptions}>
        <GetAdvButton />
      </AdvanceOptions>
      <AdvancedSettings active={advOptions}>
        <GetSlider
          lbl_txt={"Guidance"}
          lbl_desc={
            "Higher values will make the image closer to the prompt, recommended value is 9"
          }
          lbl_value={sdProps['cfg']}
          min={0}
          max={40}
          step={0.5}
          sdProps={sdProps}
          setSdProps={setSdProps}
          prop_name={'cfg'}
        />
        <GetSlider
          lbl_txt={"Height"}
          lbl_desc={
            "Adjust the height of the image, higher values may end up taking longer duration to generate."
          }
          min={384}
          max={1024}
          step={64}
          sdProps={sdProps}
          setSdProps={setSdProps}
          prop_name={'height'}
        />
        <GetSlider
          lbl_txt={"Width"}
          lbl_desc={
            "Adjust the width of the image, higher values may end up taking longer duration to generate."
          }
          min={384}
          max={1024}
          step={64}
          sdProps={sdProps}
          setSdProps={setSdProps}
          prop_name={'width'}
        />
        <GetSlider
          lbl_txt={"Step"}
          lbl_desc={
            "Increase the value for high quality images, but will take longer"
          }
          min={20}
          max={150}
          step={1}
          sdProps={sdProps}
          setSdProps={setSdProps}
          prop_name={'step'}
          
        />
        <GetSlider
          lbl_txt={"Number of Images"}
          lbl_desc={
            "Different variants of the image will be created for the same prompt with different seeds"
          }
          min={1}
          max={4}
          step={1}
          sdProps={sdProps}
          setSdProps={setSdProps}
          prop_name={'numImages'}
          
        />
        <SliderComponent>
          <Label>
            Seed
            <p>Leave it blank for random image generations</p>
          </Label>
          <input
            type="text"
            placeholder="Random seed"
            value={sdProps['seedValue']}
            onChange={(e) => {
              let temp = {...sdProps}
              temp['seedValue'] = e.target.value
              setSdProps(temp)
            }}
          />
        </SliderComponent>
      </AdvancedSettings>
    </Wrapper>
  );
}

const SliderMeta = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 2px solid red; */
  padding: 0.4rem 0rem 0.4rem 0rem;
  opacity: 0.8;
  p{
    margin: 0;
    padding: 0;
    
  }
`
const SliderDesc = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0rem 0.8rem 0rem;
  opacity: 0.6;
  p{
    margin: 0;
    padding: 0;
  }
`
const AdvancedSettings = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  Label {
    margin: 0;
    padding: 0;
    font-weight: 700;
    opacity: 0.8;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    p {
      font-weight: 400;
      font-size: 0.9rem;
      margin: 0;
      padding: 0;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;

      opacity: 0.6;
    }
  }
`;

const Warn = styled.p`
  margin: 5px 50px;
  display: ${(props) => (props.signedin ? "block" : "none")};
`;

const MySlider = styled(Slider)`
  [data-reach-slider-handle] {
    background: #91919c;
  }

  [data-reach-slider-range] {
    background: #6f6fd1;
    /* opacity: 0.8; */
  }

  [data-reach-slider-track] {
    opacity: 0.7;
  }
`;
const Wrapper = styled.div`
  color: white;
  display: flex;
  padding: 0 10px 20px 10px;
  /* border: 2px solid red; */
  flex-direction: column;
  margin-top: 1rem;
  background: #100f0f;
  gap: 20px;
`;

const Label = styled.label`
  
  margin-top: 5px;
  padding-top: 5px;
  font-weight: 700;
  opacity: 0.8;
  p {
    font-weight: 400;
    font-size: 0.9rem;
    margin: 0;
    margin-top: 0.2rem;

    opacity: 0.6;
  }
  /* margin:5px 50px; */
`;

const PromptInput = styled.textarea`
  height: 100px;
  background: #202024;
  border: 0.2px solid white;
  border-radius: 11px;
  font-size: 1rem;
  color: white;
  &:hover {
    background: #313134;
  }
`;

const Input = styled.textarea`
  height: 50px;
  background: #202024;
  border: 0.2px solid white;
  border-radius: 11px;
  font-size: 1rem;
  color: white;
  &:hover {
    background: #313134;
  }
`;

const SliderComponent = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  border-top: 0.1px dotted grey;
  padding-bottom:2rem;
  /* gap: 10px; */
  /* border: 2px solid red; */
  /* gap:10px; */
  /* margin:15px 50px */
  input {
    height: 30px;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    background: #202024;
    border: 0.2px solid white;
    border-radius: 4px;
    font-size: 1rem;
    color: white;
  }
`;



const Button = styled.button`
  height: 61.19px;
  /* background: #313134; */
  /* background: hsla(221, 45%, 73%, 1); */
  /* margin:0px 50px; */
  margin-top: 5px;
  border: none;
  border-radius: 8px;
  font-size: 1.3rem;
  color: #ffffff;
  &:hover {
    outline: 0.2px solid white;

    &:disabled {
      background: hsla(221, 45%, 73%, 1);
      /* background: grey; */
    }

    
  }
  
  &:not(disabled) {
    background: linear-gradient(90deg, hsla(213, 77%, 14%, 1) 0%, hsla(202, 27%, 45%, 1) 100%);
  }
  &:disabled {
    background: linear-gradient(90deg, hsla(180, 13%, 58%, 1) 0%, hsla(335, 16%, 61%, 1) 100%);
  }
`;

const AdvanceOptions = styled.button`
  color: white;
  background: #100f0f;
  border: none;
  /* border: 2px solid red; */
  align-self: center;
  &:hover {
    cursor: pointer;
  }
  /* &:active {
        background: #e5e5e5;
        box-shadow: inset 0px 0px 5px #c1c1c1;
        outline: none;
    } */
`;
// const DownArrow = styled(ChevronsDown)`
//   /* margin : 0; */
// `;
