import { useState, useEffect } from "react";
import styled from "styled-components";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { Dialog } from "@reach/dialog"; //DialogOverlay, DialogContent
import "@reach/dialog/styles.css";
import { LogOut, User, LogIn, FileText, Album } from "lucide-react";

import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { ReactComponent as A2ALogo } from "../imgs/Abrown2.svg";

export default function GlobalNav({ user }) {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(!showDialog);
  const [picture, setPicture] = useState("");
  const navigate = useNavigate();

  const onSignIn = ({ e }) => {
    navigate(`/signin`);
    setShowDialog(false);
  };
  const onTerms = () => {
    navigate(`/termsandservices`);
    setShowDialog(false);
  };

  const onGallery = () => {
    navigate(`/mygallery`);
    setShowDialog(false);
  };

  useEffect(() => {
    if (user !== null) {
      setPicture(user?.attributes["picture"]);
    }
  }, [user]);

  return (
    <Nav>
      <Logo to="/">
        <LogoContainer>
          <A2ALogo height={47} width={50} />
        </LogoContainer>
        <p>Art2Art</p>
      </Logo>
      {/* {!(user?.getUsername()) && <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })}>SignIn</button>}     */}

      
      
        <RightControls>
          <CreateButton to="/create">
            <p>Create Image</p>
          </CreateButton>
          {!user?.getUsername() && (
        <BlankPic>
          <button onClick={open}>
            <User size={18} />
            <ProfileDialog isOpen={showDialog} onDismiss={open}>
              <ul>
                <ul>
                  <button onClick={onSignIn}>
                    <LogIn size={15} /> Sign In
                  </button>
                </ul>
                <ul>
                  <button onClick={onTerms}>
                    <FileText size={15} /> Terms of services
                  </button>
                </ul>
              </ul>
            </ProfileDialog>
          </button>
        </BlankPic>
      )}
          {user?.getUsername() && (
          <ProfilePic>
            <button onClick={open}>
              <img src={picture} alt="" referrerPolicy="no-referrer" />
              <ProfileDialog isOpen={showDialog} onDismiss={open}>
                <ul>
                  <span>{user.attributes["given_name"]}</span>
                </ul>
                <ul>
                  <button onClick={onGallery}>
                    <Album size={15} /> My Gallery
                  </button>
                </ul>
                <ul>
                  <button
                    onClick={() =>
                      Auth.signOut({
                        provider: CognitoHostedUIIdentityProvider.Google,
                      })
                    }
                  >
                    <LogOut size={15} /> Log out
                  </button>
                </ul>
                <ul>
                  <button onClick={onTerms}>
                    <FileText size={15} /> Terms of services
                  </button>
                </ul>
              </ProfileDialog>
            </button>
          </ProfilePic>)}
        </RightControls>
      
    </Nav>
  );
}

const ProfileDialog = styled(Dialog)`
  margin: 0;
  padding: 0;
  margin-top: 40px;
  padding: 10px;
  /* padding-left:10px; */
  position: absolute;
  background: #202024;
  border-radius: 4px;
  color: white;
  /* top:45px; */
  right: 10px;
  width: 200px;
  span {
    padding-bottom: 2px;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    padding-top: 5px;
    list-style: none;
  }

  button {
    margin: 0;
    padding: 4px;
    outline: none;
    border: none;
    background: #202024;
    color: white;
    width: 100%;
    text-align: left;

    &:hover {
      background: #313134;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const BlankPic = styled.div`
  button {
    border-radius: 50%;
    border: none;
  }
`;
const ProfilePic = styled.div`
  button {
    outline: none;
    border: none;
    margin-top: 0.5rem;
    background: #202024;
    &:hover {
      cursor: pointer;
    }
  }
  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
`;
const RightControls = styled.div`
  display: flex;
  align-items: center;
`;
const LogoContainer = styled.div`
  margin-top: 6px;
`;
const Logo = styled(Link)`
  display: flex;
  margin-top: 2px;

  /* border: 1px solid red; */
  p {
    margin: 0;
    padding: 0;
    color: #dea028;
    align-self: center;
  }
`;

const TextWrapper = styled.span`
  color: #dea028;
  align-self: center;
`;

const CreateButton = styled(Link)`
  margin-right: 20px;

  background: #202024;
  font-size: 0.8rem;
  color: white;
  align-items: center;

  height: 30px;
  border-radius: 4px;
  background-color: #8b3dff;
  display: flex;
  &:hover {
    cursor: pointer;
    background-color: #9e77f3;
  }

  p {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    margin: 0px;
  }
`;

const Nav = styled.div`
  display: flex;
  padding: 0px 20px;
  flex-shrink: 0;
  background: #202024;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  a {
    text-decoration: none;
  }
`;
